export const colors = {
  pink: '#e8a3bb',
  pinkHover: '#de93ad',
  // orange: '#f16522',
  green: '#0fa58c',
  lightBlue: '#A3CEF1',
  lightBlueHover: '#6d8b9e',
  blue: '#1f3c7c',

  orange: '#E68105',
  orangeHover: '#d76406',
  black: '#000',
  lightGray: '#D1D1D1',
  grey: '#68717C',
  white: '#fff',
  clearWhite: 'rgba(225, 225, 225, 0.9)',
  clear: 'rgba(0, 0, 0, 0)',

  disabledRed: '#E4B3AA',
  red: '#E66A64',
  hoverRed: '#C64C4C',
  tan: '#c5b8b1',
  darkTan: '#A68474',
  hoverDarkTan: '#875d4a',
};
